<template>
  <b-card header="Hasil Studi Mahasiswa" body-class="pt-0">
    <b-row>
      <b-col>
        <DetailMahasiswa
          :mhs="getUserProfile"
          :loading="loadingMahasiswa"
        />
      </b-col>
      <b-col>
        <PrestasiAkademikMahasiswa
          :prestasi="getHasilStudiDetail"
          :loading="loadingHasilStudi"
        />
      </b-col>
    </b-row>

    <div id="kartu-hasil-studi">
      <SemesterMatakuliah
        @onChange="filterBySemester"
        :loading="loading"
        :semester-list="semesterList"
        :selected-semester="semesterId"
      >
        <title-icon-button
          tooltipText="Mutakhir Data"
          @onClick="refreshData"
        >
          <template v-slot:icon>
            <refresh-icon></refresh-icon>
          </template>
        </title-icon-button>
      </SemesterMatakuliah>

      <c-table
        :table-data="items"
        :fields="captions"
        primary-key="klsId"
        :loading="loadingItems"
      >
        <template v-slot:cell(index)="{ index }">
          {{ index+1 }}
        </template>
        <template v-slot:cell(krsdtKodeNilai)="{ item }">
          {{ item.krsdtKodeNilai | nilai }}
        </template>
        <template v-slot:cell(detail)="{ item }">
          <TitleIconButton
            :custom-icon="true"
            tooltipText="Detail Komponen Nilai"
            @onClick="showGradeDetailModal(item)"
          >
            <template v-slot:icon>
              <info-icon />
            </template>
          </TitleIconButton>
        </template>
      </c-table>
    </div>
  </b-card>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import {
  isEmpty, isNil, flatten, parseIfNotNull, activeSemesterObject
} from '@/helpers'
import {
  TableMixin, Table as CTable,
  TitleIconButton, InfoIcon, RefreshIcon, ModalMessageMixin
} from '@/components'
// module
import SemesterMatakuliah from '@/modules/akademik/Semester'
import DetailMahasiswa from '@/modules/akademik/MahasiswaDetail'
import PrestasiAkademikMahasiswa from '@/modules/matakuliah/PrestasiAkademikDetail'

export default {
  name: 'HasilStudi',
  mixins: [TableMixin, ModalMessageMixin],
  filters: {
    nilai: (val) => parseIfNotNull(val)
  },
  components: {
    DetailMahasiswa,
    PrestasiAkademikMahasiswa,
    CTable,
    TitleIconButton,
    SemesterMatakuliah,
    RefreshIcon,
    InfoIcon
  },
  data () {
    return {
      fields: [
        { key: 'index', label: '#', sortable: true },
        { key: 'vmktwkrsMkkurKode', label: 'Kode', sortable: true },
        { key: 'vmktwkrsMkkurNamaResmi', label: 'Matakuliah', sortable: true },
        { key: 'klsNama', label: 'Nama Kelas', sortable: true },
        { key: 'krsdtSifatMatakuliah', label: 'W/P', sortable: true },
        { key: 'vmktwkrsJumlahSksKrs', label: 'SKS', sortable: false },
        { key: 'krsdtKodeNilai', label: 'Nilai', sortable: false },
        { key: 'detail', label: 'Detil', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters('users', ['getUserProfile']),
    ...mapState('krs', {
      semesterId: 'semesterId',
      semesterList: 'semesterStudentList',
      loading: 'loadingSemester',
      loadingMahasiswa: 'loadingMahasiswa',
      loadingHasilStudi: 'loadingHasilStudi',
      getHasilStudiDetail: 'hasilStudiDetail'
    }),
    ...mapState('faculty', ['activeSemesterId'])
  },
  beforeMount () {
    this.getAllStudentSemester().then(() => {
      if (isNil(this.semesterId)) {
        this.filterBySemester(this.activeSemesterId)
      } else {
        this.filterBySemester(this.semesterId)
      }
    })
  },
  methods: {
    ...mapMutations({
      setSelectedSemester: 'krs/setSelectedSemesterId'
    }),
    ...mapActions({
      getAllStudyResults: 'krs/getAllStudyResults',
      getAllStudentSemester: 'krs/getAllStudentSemester'
    }),
    filterBySemester (semesterId) {
      this.setSelectedSemester(semesterId)

      const semester = activeSemesterObject(this.semesterList, this.semesterId)

      if (isNil(semester) !== true) {
        this.tableDataProvider()
      }
    },
    refreshData () {
      if (isNil(this.semesterId)) {
        this.warningModalMessage('Pilih semester terlebih dahulu.')
      } else {
        this.filterBySemester(this.semesterId)
      }
    },
    dataProvider () {
      return this.getAllStudyResults()
    },
    async showGradeDetailModal (data) {
      let messageBody = 'Komponen Nilai Tidak ditemukan untuk matakuliah ini.'

      if (!isEmpty(data.nilai)) {
        const el = this.$createElement

        const nilaiEl = flatten(data.nilai.map(nilai => {
          return [
            el('dt', { class: ['col-7'] }, [nilai.komPenNama]),
            el('dd', { class: ['col-5'] }, [parseIfNotNull(nilai.nilai)])
          ]
        }))
        const messageVNode = el('dl', { class: ['row'] }, nilaiEl)

        messageBody = [messageVNode]
      }

      await this.resolvedModalBox(messageBody, {
        title: 'Detail Komponen Nilai',
        centered: true
      })
    }
  }
}
</script>
