<template>
  <b-row>
    <b-col
      sm="12"
      md="4"
      lg="4"
    >
      <loading-card
        title="Keterangan Nilai"
        :loading="loading"
      >
        <dl>
          <div
            class="row"
            v-for="nilai of ketNilai"
            :key="nilai.kode"
          >
            <dt class="col-lg-4">
              {{ nilai.kode }}
            </dt>
            <dd class="col-lg-8">
              {{ nilai.bobot }}
            </dd>
          </div>
        </dl>
      </loading-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LoadingCard from '@/components/LoadingCard'

export default {
  name: 'KeteranganNilai',
  components: {
    LoadingCard
  },
  data () {
    return {
      loading: false
    }
  },
  computed: mapState('faculty', {
    ketNilai: 'keteranganNilai'
  }),
  beforeMount () {
    this.loading = true
    this.getGradesList().then(() => {
      this.loading = false
    })
  },
  methods: mapActions('faculty', {
    getGradesList: 'keteranganNilai'
  })
}
</script>
