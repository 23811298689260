<template>
  <div id="detail-bimbingan-akademik">
    <b-card header-class="pl-2 card-header-inline" body-class="pt-0" class="shadow-sm">
      <template v-slot:header>
        <BackButton
          class="mr-1"
          :redirect-to="backButtonRedirect"
        />
        <header class="pt-2">
          Detail Bimbingan Akademik
        </header>
      </template>

      <DetilMahasiswa :mhs="getMahasiswa" :loading="loadingMahasiswa" />

      <b-tabs>
        <b-tab
          id="krs-tab"
          title="KRS Mahasiswa"
          lazy
          class="pt-0"
        >
          <ApproveRencanaStudi />
        </b-tab>
        <b-tab
          id="khs-tab"
          title="KHS Mahasiswa"
          lazy
          class="pt-0"
        >
          <HasilStudiMahasiswa />
        </b-tab>
        <b-tab
          id="transkrip-tab"
          title="Transkrip Nilai Mahasiswa"
          lazy
          class="pt-0"
        >
          <TranskripNilaiMahasiswa />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { BackButton } from '@/components/buttons'
// module
import DetilMahasiswa from '@/modules/akademik/MahasiswaDetail'
import ApproveRencanaStudi from '@/modules/akademik/RencanaStudiApprove'
import HasilStudiMahasiswa from '@/modules/akademik/HasilStudi'
import TranskripNilaiMahasiswa from '@/modules/akademik/Transkrip'

export default {
  name: 'BimbinganAkademik',
  components: {
    TranskripNilaiMahasiswa,
    HasilStudiMahasiswa,
    ApproveRencanaStudi,
    DetilMahasiswa,
    BackButton
  },
  data () {
    return {
      backButtonRedirect: {
        name: 'dosen.bimbingan-akademik'
      }
    }
  },
  computed: {
    ...mapState('krs', {
      loadingMahasiswa: 'loadingMahasiswa',
      getMahasiswa: 'mahasiswa'
    })
  },
  beforeDestroy () {
    this.flushMahasiswa()
  },
  methods: mapMutations('krs', ['flushMahasiswa'])
}
</script>

<style scoped>
.card-header-inline {
  display: inline-flex;
}
</style>
