<template>
  <div id="krs-approve">
    <DetilKrs :loading="loadingKrsDetil" :krs="krsDetil" />

    <div class="mb-3">
      <c-button
        variant="outline-primary"
        button-class="mr-2"
        @onClick="onApproveKrs"
        tooltip-text="Setujui KRS Mahasiswa"
      >
        Setujui ({{ getCountSelectedKrs }})
      </c-button>
      <c-button
        variant="outline-info"
        :tooltip-text="selectAll === false ? 'Pilih Semua KRS Mahasiswa' : 'Bersihkan Pilihan KRS Mahasiswa'"
        @onClick="selectAllKrs"
      >
        <span v-if="selectAll === false">Pilih Semua</span>
        <span v-else>Batal ({{ getCountSelectedKrs }})</span>
      </c-button>
    </div>

    <c-table
      selectable
      ref="krsTable"
      primary-key="krsdtId"
      select-mode="multi"
      :indeterminate="getIndeterminate"
      :table-data="krs"
      :fields="captions"
      :loading="loadingKrs"
      @row-selected="setSelectedKrs"
    >
      <template v-slot:head(check)>
        <indeterminate-icon v-if="getIndeterminate" />
        <checked-icon v-else-if="selectAll" />
        <unchecked-icon v-else />
      </template>
      <template v-slot:cell(check)="{ rowSelected }">
        <checked-icon v-if="rowSelected" />
        <unchecked-icon v-else />
      </template>
      <template v-slot:cell(index)="{ index }">
        {{ index+1 }}
      </template>
      <template v-slot:cell(status)="{ item }">
        <c-button
          v-if="item.krsdtApproved === false"
          variant="outline-danger"
          size="sm"
        >
          <failed-icon></failed-icon>
          Belum disetujui
        </c-button>
        <b-button
          v-else
          variant="outline-success"
          size="sm"
        >
          <success-icon></success-icon>
          Disetujui
        </b-button>
      </template>
    </c-table>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { join } from '@/helpers'
import {
  CheckedIcon, UncheckedIcon, IndeterminateIcon, FailedIcon, SuccessIcon,
  TableMixin, ModalMessageMixin, Table as CTable, Button as CButton
} from '@/components'

// modules
import DetilKrs from '@/modules/matakuliah/KrsDetail'

export default {
  name: 'ApproveRencanaStudi',
  mixins: [TableMixin, ModalMessageMixin],
  components: {
    DetilKrs,
    CTable,
    CButton,
    CheckedIcon,
    UncheckedIcon,
    IndeterminateIcon,
    FailedIcon,
    SuccessIcon
  },
  data () {
    return {
      selectAll: false,
      fields: [
        { key: 'check', label: 'Pilih', sortable: false },
        { key: 'index', label: '#', sortable: true },
        { key: 'vmktwkrsMkkurKode', label: 'Kode', sortable: true }, // mkkurKode
        { key: 'vmktwkrsMkkurNamaResmi', label: 'Nama Kelas', sortable: true }, // mkkurNamaResmi
        { key: 'vmktwkrsJumlahSksKrs', label: 'SKS', sortable: true }, // jumlahSksKrs
        { key: 'status', label: 'Status KRS', sortable: false }
      ]
    }
  },
  watch: {
    selectAll (checked) {
      if (checked === true) {
        this.$refs.krsTable.$refs.table.selectAllRows()
      } else {
        this.$refs.krsTable.$refs.table.clearSelected()
      }
    }
  },
  beforeMount () {
    this.refreshDataTable()
  },
  computed: {
    ...mapState('faculty', {
      facultyCode: 'facultyId'
    }),
    ...mapState('krs', {
      mahasiswa: 'mahasiswa',
      loadingKrs: 'loadingKrs',
      krs: 'krs',
      krsDetil: 'krsDetail',
      loadingKrsDetil: 'loadingKrsDetil'
    }),
    ...mapGetters('krs', [
      'getAllSelectedKrs',
      'getIndeterminate',
      'getCountSelectedKrs'
    ])
  },
  methods: {
    ...mapMutations('krs', [
      'setSelectedKrs',
      'setTotalKrs'
    ]),
    ...mapActions('krs', [
      'approveKrs',
      'getAllStudyPlan'
    ]),
    refreshDataTable () {
      this.getAllStudyPlan()
      this.setSelectedKrs([])
    },
    selectAllKrs () {
      this.selectAll = !this.selectAll
    },
    onApproveKrs () {
      this.$nextTick(() => {
        if (this.getCountSelectedKrs === 0) {
          this.modalMessage('Pilih KRS mahasiswa sebelum disetujui', {
            noRedirect: true
          })
        } else {
          const afterApproveAction = () => {
            this.approveKrs(this.getAllSelectedKrs).then(() => {
              // need to clear state
              this.refreshDataTable()
              this.$refs.krsTable.$refs.table.clearSelected()
            }).catch(() => {
              this.modalMessage('Gagal proses persetujuan KRS mahasiswa', {
                noRedirect: true
              })
            })
          }

          const _msg = join('', ['Setujui (', this.getCountSelectedKrs, ') krs mahasiswa?'])

          this.modalConfirmMessage(_msg, {
            title: 'Approve KRS Mahasiswa',
            size: 'md',
            actionAfter: afterApproveAction
          })
        }
      })
    }
  }
}
</script>
