<template>
  <div id="kartu-hasil-studi">
    <DetailPrestasiAkademik
      class="pt-0"
      :loading="loadingHasilStudi"
      title="Prestasi Akademik (KHS)"
      no-data="Pilih Semester Mahasiswa"
      :prestasi="getHasilStudiDetail"
    />

    <SemesterMatakuliah
      @onChange="filterBySemester"
      :loading="loadingSemester"
      :semester-list="semesterList"
      :selected-semester="semesterId"
    >
      <title-icon-button
        tooltipText="Mutakhir Data"
        @onClick="refreshData"
      >
        <template v-slot:icon>
          <refresh-icon></refresh-icon>
        </template>
      </title-icon-button>
    </SemesterMatakuliah>

    <c-table
      :table-data="items"
      :fields="captions"
      primary-key="klsId"
      :loading="loadingItems"
    >
      <template v-slot:cell(index)="{ index }">
        {{ index+1 }}
      </template>
      <template v-slot:cell(krsdtKodeNilai)="{ item }">
        {{ item.krsdtKodeNilai | nilai }}
      </template>
    </c-table>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { parseIfNotNull, activeSemesterObject, isNil } from '@/helpers'
import {
  TableMixin, Table as CTable, TitleIconButton, RefreshIcon
} from '@/components'
// module
import DetailPrestasiAkademik from '@/modules/matakuliah/PrestasiAkademikDetail'
import SemesterMatakuliah from '@/modules/akademik/Semester'

export default {
  name: 'HasilStudiMahasiswa',
  mixins: [TableMixin],
  filters: {
    nilai: (val) => parseIfNotNull(val)
  },
  components: {
    CTable,
    TitleIconButton,
    SemesterMatakuliah,
    DetailPrestasiAkademik,
    RefreshIcon
  },
  data () {
    return {
      fields: [
        { key: 'index', label: '#', sortable: true },
        { key: 'vmktwkrsMkkurKode', label: 'Kode', sortable: true },
        { key: 'vmktwkrsMkkurNamaResmi', label: 'Matakuliah', sortable: true },
        { key: 'klsNama', label: 'Nama Kelas', sortable: true },
        { key: 'krsdtSifatMatakuliah', label: 'W/P', sortable: true },
        { key: 'vmktwkrsJumlahSksKrs', label: 'SKS', sortable: false },
        { key: 'krsdtKodeNilai', label: 'Nilai', sortable: false }
      ]
    }
  },
  computed: {
    ...mapState('krs', {
      semesterId: 'semesterId',
      semesterList: 'semesterStudentList',
      getHasilStudiDetail: 'hasilStudiDetail',
      loadingSemester: 'loadingSemester',
      loadingHasilStudi: 'loadingHasilStudi'
    }),
    ...mapState('faculty', {
      activeSemesterId: 'activeSemesterId'
    })
  },
  async beforeMount () {
    await this.getAllStudentSemester()
    const semesterId = isNil(this.semesterId) ? this.activeSemesterId : this.semesterId
    await this.filterBySemester(semesterId)
    await this.refreshData()
  },
  methods: {
    ...mapMutations({
      setSelectedSemester: 'krs/setSelectedSemesterId'
    }),
    ...mapActions({
      getAllStudyResults: 'krs/getAllStudyResults',
      getAllStudentSemester: 'krs/getAllStudentSemester'
    }),
    async filterBySemester (semesterId) {
      this.setSelectedSemester(semesterId)

      const semester = activeSemesterObject(this.semesterList, this.semesterId)

      if (isNil(semester) !== true) {
        await this.tableDataProvider()
      }
    },
    async refreshData () {
      await this.filterBySemester(this.semesterId)
    },
    dataProvider () {
      return this.getAllStudyResults()
    }
  }
}
</script>

<style scoped>
.card-header-inline {
  display: inline-flex;
}
</style>
