<template>
  <b-row class="mb-4">
    <b-col
      sm="6"
      md="4"
      lg="4"
      xl="3"
    >
      <label>Semester</label>
      <div class="d-flex">
        <b-form-select
          value-field="smtId"
          text-field="smtNama"
          :value="selectedSemester"
          :options="semesterList"
          @change="onChange"
          :disabled="loading"
        >
          <slot name="first">
            <option :value="null">
              {{ loadingSelectText }}
            </option>
          </slot>
        </b-form-select>

        <div class="px-2">
          <slot />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'SemesterMatakuliah',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    semesterList: {
      type: Array,
      default: () => []
    },
    selectedSemester: {
      type: String,
      default: null
    }
  },
  computed: {
    loadingSelectText () {
      return this.loading ? 'Memuat data...' : '- Pilih Semester -'
    }
  },
  methods: {
    onChange (val) {
      this.$emit('onChange', val)
    }
  }
}
</script>
