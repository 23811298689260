<template>
  <loading-card
    :loading="loading"
    title="Informasi Mahasiswa"
  >
    <dl class="row">
      <dt class="col-lg-4">
        No. BP
      </dt>
      <dd class="col-lg-8">
        {{ mhs.mhsNiu }}
      </dd>
      <dt class="col-lg-4">
        Nama Mahasiswa
      </dt>
      <dd class="col-lg-8">
        {{ mhs.mhsNama }}
      </dd>
      <dt class="col-lg-4">
        Program Studi
      </dt>
      <dd class="col-lg-8">
        {{ mhs.programStudi }}
      </dd>
    </dl>
  </loading-card>
</template>

<script>
import LoadingCard from '@/components/LoadingCard'

export default {
  name: 'DetailMahasiswaMatakuliah',
  components: {
    LoadingCard
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    mhs: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
