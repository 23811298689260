<template>
  <div id="transkrip-nilai">
    <DetailPrestasiAkademik
      class="pt-0"
      :loading="loading"
      title="Prestasi Akademik (Transkrip)"
      :prestasi="getTranskripDetail"
    />

    <c-table
      :table-data="items"
      :fields="captions"
      primary-key="klsId"
      :loading="loadingItems"
    />

    <KeteranganNilai />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { TableMixin, ModalMessageMixin } from '@/components/mixins'
import CTable from '@/components/Table'
// module
import DetailPrestasiAkademik from '@/modules/matakuliah/PrestasiAkademikDetail'
import KeteranganNilai from '@/modules/matakuliah/KeteranganNilai'

export default {
  name: 'TranskripNilaiMahasiswa',
  mixins: [TableMixin, ModalMessageMixin],
  components: {
    DetailPrestasiAkademik,
    KeteranganNilai,
    CTable
  },
  data () {
    return {
      fields: [
        { key: 'semester', label: 'Semester' },
        { key: 'traKodeMatakuliah', label: 'Kode' },
        { key: 'traNamaResmi', label: 'Mata Kuliah' },
        { key: 'traJumlahSks', label: 'SKS' },
        { key: 'traKodeNilai', label: 'Nilai' }
      ],
      backButtonRedirect: {
        name: 'dosen.bimbingan-akademik'
      }
    }
  },
  computed: {
    ...mapState('krs', {
      loading: 'loadingTranskrip',
      getTranskripDetail: 'transkripDetail'
    })
  },
  beforeMount () {
    this.tableDataProvider()
  },
  methods: {
    ...mapActions('krs', {
      getStudentGradeTranscripts: 'getStudentGradeTranscripts'
    }),
    dataProvider (page) {
      return this.getStudentGradeTranscripts()
    }
  }
}
</script>
