<template>
  <loading-card
    :loading="loading"
    title="Informasi Studi Mahasiswa"
  >
    <dl class="row">
      <dt class="col-lg-4">
        Semester
      </dt>
      <dd class="col-lg-8">
        {{ krs.semester }}
      </dd>
      <dt class="col-lg-4">
        SKS Maksimum
      </dt>
      <dd class="col-lg-8">
        {{ krs.ipMaxSks }}
      </dd>
      <dt class="col-lg-4">
        Total SKS
      </dt>
      <dd class="col-lg-8">
        {{ krs.totalSks }}
      </dd>
      <dt class="col-lg-4">
        Total Matakuliah
      </dt>
      <dd class="col-lg-8">
        {{ krs.totalMataKuliah }}
      </dd>
    </dl>
  </loading-card>
</template>

<script>
import LoadingCard from '@/components/LoadingCard'

export default {
  name: 'DetailKrsMatakuliah',
  components: {
    LoadingCard
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    krs: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
