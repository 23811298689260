<template>
  <loading-card
    :title="title"
    :loading="loading"
  >
    <dl class="row">
      <dt class="col-lg-4">
        Jumlah SKS diambil
      </dt>
      <dd class="col-lg-8">
        {{ prestasi.jumlahSksDiambil }}
      </dd>
      <dt class="col-lg-4">
        Jumlah Matakuliah diambil
      </dt>
      <dd class="col-lg-8">
        {{ prestasi.jumlahMatakuliahDiambil }}
      </dd>
      <dt class="col-lg-4">
        IP Kumulatif
      </dt>
      <dd class="col-lg-8">
        {{ prestasi.ipIp }}
      </dd>
    </dl>
  </loading-card>
</template>

<script>
import LoadingCard from '@/components/LoadingCard'

export default {
  name: 'DetailPrestasiAkademik',
  components: {
    LoadingCard
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'Prestasi Akademik'
    },
    prestasi: {
      type: Object,
      default: () => ({
        jumlahSksDiambil: '-',
        jumlahMatakuliahDiambil: '-',
        ipIp: '-'
      })
    }
  }
}
</script>
